import { FC, useCallback, useEffect, useState } from "react";
import { ChangeStep, Step } from "../types/authorizationTypes";
import s from './Authorization.module.scss';
import classNames from "classnames";
import { Registration, RegistrationStepProps } from "../../../features/Registration";
import { SmsCode } from "../../../features/SmsCode";
import { Login, LoginProps } from "../../../features/Login";
import { Restore } from "../../../features/Restore";
import { useQuery } from "../../../shared/hooks";
import { RoutePath } from "../../../shared/config/routeConfig/routeConfig";
import { useNavigate } from "react-router-dom";
import { useLazyFormRedirectQuery } from "../api/authorizationApi";
import { useIsAuth } from "../../../shared/hooks/useIsAuth";
import { useFetchProfileQuery } from "../../../entities/User/api/userApi";

interface AuthorizationProps {
  className?: string;
}

const getAllSearchParams = (search: URL['search']) => {
  const searchParams = search.slice(1).split('&');

  return searchParams.map((searchParam) => searchParam.split('='));
}

export const Authorization: FC<AuthorizationProps> = (props) => {
  const {
    className,
  } = props;

  const navigate = useNavigate();
  const query = useQuery();
  const isAuth = useIsAuth();
  const { data: userData, isSuccess: isSuccessUserData } = useFetchProfileQuery();

  const formId = query.get('fid');
  const [reqFormRedirect, { data: formDataRedirect, isSuccess }] = useLazyFormRedirectQuery();

  useEffect(() => {
    if (isAuth && formId) {
      reqFormRedirect({ form_id: Number(formId) });
    }
  }, [isAuth, formId]);

  const [step, setStep] = useState<Step>('registration');

  const changeStep: ChangeStep = (newWay) => {
    setStep(newWay)
  }

  const toLoginStep = useCallback(() => {
    changeStep('login');
  }, []);

  const toSmsCodeStep = useCallback(() => {
    changeStep('smsCode');
  }, []);

  const toRegistration = useCallback(() => {
    changeStep('registration');
  }, []);

  const toRestore = useCallback(() => {
    changeStep('restore');
  }, []);

  const doRedirect = () => {
    const redirectLinkFromBackend = formDataRedirect?.result.redirect_url;
    const redirectLinkFromUrl = query.get('redirect');
    console.log(redirectLinkFromBackend);
    console.log(userData);

    if (redirectLinkFromBackend) {
      const newRedirectLinkFromBackend = new URL(redirectLinkFromBackend);
      
      if (userData?.result) {
        newRedirectLinkFromBackend.searchParams.set('email', String(userData.result.email));
        newRedirectLinkFromBackend.searchParams.set('id', String(userData.result.id));
      }

      const otherSearchParams = getAllSearchParams(new URL(window.location.href).search);

      otherSearchParams.forEach(([name, value]) => {
        if (name === 'fid') {
          return;
        }

        newRedirectLinkFromBackend.searchParams.set(name, value);
      })

      window.location.href = newRedirectLinkFromBackend.toString();
      return;
    }

    if (redirectLinkFromUrl) {
      navigate(redirectLinkFromUrl);
      return;
    }

    navigate(RoutePath.main);
  }

  useEffect(() => {
    const abc = !formId || isSuccess;
    
    if (isAuth && abc && isSuccessUserData) {
      doRedirect();
    }
  }, [isAuth, isSuccess, isSuccessUserData]);

  const onStepComplitedRegistration: RegistrationStepProps['onStepComplited'] = useCallback(({ isPhoneConfirmed }) => {
    // @ts-ignore
    ym(96586181,'reachGoal','createnewuser')

    if (!isPhoneConfirmed) {
      toSmsCodeStep();
      return;
    }
  }, [toSmsCodeStep]);

  const onStepCompletedLogin: LoginProps['onStepCompleted'] = useCallback(({ isPhoneConfirmed }) => {
    if (!isPhoneConfirmed) {
      toSmsCodeStep();
      return;
    }
  }, [query, toSmsCodeStep]);

  const onStepCompleted = () => {}

  const content = useCallback(() => {
    if (step === 'restore') {
      return (
        <Restore
          onStepCompleted={onStepCompleted}
          toLoginStep={toLoginStep}
        />
      );
    }

    if (step === 'smsCode') {
      return (
        <SmsCode onStepComplited={toLoginStep} />
      );
    }
  
    if (step === 'registration') {
      return (
        <Registration onStepComplited={onStepComplitedRegistration} toLogin={toLoginStep} />
      );
    }
  
    return (
      <Login toRegistration={toRegistration} onStepCompleted={onStepCompletedLogin} goRestore={toRestore} />
    );
  }, [step, toRegistration, onStepCompletedLogin, toRestore, toLoginStep, onStepComplitedRegistration]);

  return (
    <div className={classNames(s.authorization, className)}>
      {content()}
    </div>
  );
}
