import { FC } from "react";
import s from './TextItem.module.scss';
import InnerHTML from 'dangerously-set-html-content';

interface TextItemProps {
  text: string;
}

export const TextItem: FC<TextItemProps> = ({ text }) => {
  return (
    <div className={s.textItem}>
      <InnerHTML html={text} />
    </div>
  );
};
